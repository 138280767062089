












import {Component, Vue} from 'vue-property-decorator'
import googleDriveService from "@/service/google-drive.service";
import {Trainer} from "@/model/trainer";
import TrainerItem from "@/components/sections/trainer/TrainerItem.vue";
import {AQUAWAY_SPREADSHEET_ID} from "@/constants/google-spreadsheet-constant";

@Component({components: {TrainerItem}})
export default class Trainers extends Vue {
  loading = true;
  trainers: Trainer[] = [];

  mounted(): void {
    this.loading = true;
    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    googleDriveService.readData(AQUAWAY_SPREADSHEET_ID).then(response => {
      response.loadInfo().then(() => {
        this.loading = false;
        const sheet = response.sheetsByIndex[1]
        sheet.getRows()
            .then((r: Trainer[]) => {
              r.forEach((item: Trainer) => {
                this.trainers.push(
                    item
                )
              });
            })
      })
    })
  }
}
