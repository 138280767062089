









import {Component, Vue} from "vue-property-decorator";
import AppBar from "@/components/appbar/AppBar.vue";
import AquawayFooter from "@/components/AquawayFooter.vue";

@Component({
  components: {AppBar,AquawayFooter}
})
export default class App extends Vue {

}
