





import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class BaseSlideComponent extends Vue {
  @Prop()
  image!: string;
}
