


































import {Component, Prop} from 'vue-property-decorator'
import BaseSlideComponent from "@/components/sections/slides/BaseSlideComponent.vue";

@Component({})
export default class MainSlideComponent extends BaseSlideComponent {
  @Prop()
  image!: string;
}
