







import {Component, Vue} from 'vue-property-decorator';
import Slider from "@/components/sections/Slider.vue";
import Trainers from "@/components/sections/Trainers.vue";

@Component({
  components: {
    Trainers,
    Slider
  }
})
export default class Landing extends Vue {

}
