import {GoogleSpreadsheet} from 'google-spreadsheet';

const secretKey = require('./secrect/aquaway-landing-d383eff3d92b.json');

class GoogleDriveService {
  public async readData(spreadSheet: string): Promise<GoogleSpreadsheet> {
    return new Promise((resolve) => {
      const doc: GoogleSpreadsheet = new GoogleSpreadsheet(spreadSheet);
      doc.useServiceAccountAuth(secretKey);
      resolve(doc)
    })

  }
}


export default new GoogleDriveService();
