





import {Component, Vue} from 'vue-property-decorator'
import MapComponent from "@/components/map/MapComponent.vue";

@Component({
  components: {MapComponent}
})
export default class MapView extends Vue {
}
