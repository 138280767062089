






































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ActionsMenu extends Vue {
  items = [
    {path: '/', title: 'Главная', icon: 'mdi-home-outline', color: 'blue'},
    {path: '/register', title: 'Регистрация', icon: 'mdi-account-plus-outline', color: 'blue'},
    {path: '/plan', title: 'Расписание', icon: 'mdi-view-dashboard-outline', color: 'blue'},

  ];

}
