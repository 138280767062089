import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Landing from '../views/Landing.vue'
import Register from "@/views/Register.vue";
import WorkoutPlan from "@/views/WorkoutPlan.vue";
import MapView from "@/views/MapView.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/plan',
    name: 'WorkoutPlan',
    component: WorkoutPlan
  },
  {
    path: '/map',
    name: 'MapView',
    component: MapView
  },
  {
    path: "/http*",
    beforeEnter: to => {
      window.open(to.fullPath.substring(1), '_blank');
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();

});

export default router
