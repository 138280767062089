
































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Trainer} from "@/model/trainer";

@Component({components: {}})
export default class TrainerItem extends Vue {
  @Prop()
  trainer!: Trainer
}

