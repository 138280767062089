
















































import {Component, Vue, Watch} from 'vue-property-decorator';
import googleDriveService from "@/service/google-drive.service";
import WorkoutPlanListItem from "@/components/workoutplan/WorkoutPlanListItem.vue";
import {WorkoutPlanItem} from "@/model/workout-plan-item";
import {AQUAWAY_SPREADSHEET_ID} from "@/constants/google-spreadsheet-constant";

@Component({
  components: {WorkoutPlanListItem}
})
export default class WorkoutPlanList extends Vue {

  planItems: Array<WorkoutPlanItem> = [];
  filteredPlanItems: Array<WorkoutPlanItem> = [];
  loading = true;
  phrase = '';

  mounted(): void {
    this.loading = true;
    this.fetch();
    this.filteredPlanItems = this.find(this.phrase);
  }

  @Watch('phrase')
  searchByPhrase(phrase: string): void {
    this.filteredPlanItems = this.find(phrase);
  }

  find(phrase: string): Array<WorkoutPlanItem> {
    if (!phrase.length) {
      return this.planItems;
    }

    return this.planItems.filter(item => Object.keys(item).filter(k => !k?.includes('_')).filter(i => item[i] && item[i]?.toLowerCase()?.includes(phrase?.toLowerCase()))?.length
    )
  }

  fetch(): void {
    this.loading = true;
    googleDriveService.readData(AQUAWAY_SPREADSHEET_ID).then(response => {
      response.loadInfo().then(() => {
        this.loading = false;
        const sheet = response.sheetsByIndex[0]
        sheet.getRows()
            .then((r: WorkoutPlanItem[]) => {
              r.filter(i => i.id).sort((a, b) => (a?.id! > b?.id! ? 1 : -1)).forEach((item: WorkoutPlanItem) => {
                this.planItems.push(
                    item
                )
              });
            })
      })
    })
  }

}
