















import {Component, Vue} from 'vue-property-decorator';
import ActionsMenu from "@/components/appbar/ActionMenu.vue";

@Component({
  components: {ActionsMenu}
})
export default class AppBar extends Vue {

}
