










import {Component, Vue} from 'vue-property-decorator'
import SimpleSlideComponent from "@/components/sections/slides/SimpleSlideComponent.vue";
import MainSlideComponent from "@/components/sections/slides/MainSlideComponent.vue";

@Component({components: {MainSlideComponent, SimpleSlideComponent}})
export default class Slider extends Vue {
  slides = [
    {
      src: 'https://wallpaperaccess.com/full/148924.jpg',
      component: 'MainSlideComponent'
    },
    {
      src: 'https://images.pexels.com/photos/863988/pexels-photo-863988.jpeg',
      component: 'SimpleSlideComponent'
    },
    {
      src: 'https://wallpaper-mania.com/wp-content/uploads/2018/09/High_resolution_wallpaper_background_ID_77701592395.jpg',
      component: 'SimpleSlideComponent'
    },
    {
      src: 'https://images.squarespace-cdn.com/content/v1/5b1d02a1af20966d8839aad5/1562233923162-MHW4EJH7MJTE4KI8GTN8/Children+Swimming+Lessons.jpg?format=1500w',
      component: 'SimpleSlideComponent'
    },
  ]
}
