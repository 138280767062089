



































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {WorkoutPlanItem} from "@/model/workout-plan-item";

export interface WeekDay {
  name?: string;
  room?: string;
  water?: string;
}

@Component({})
export default class WorkoutPlanListItem extends Vue {

  @Prop()
  item!: WorkoutPlanItem;
  days: Array<WeekDay> = [];
  panel = [];

  showInfoIcon(text: string): boolean {
    return text.includes('*')
  }

  clean(text: string): string {
    return text.replace('*', '')
  }

  workoutDays(item: WorkoutPlanItem): Array<WeekDay> {
    const arr: Array<WeekDay> = [];
    if (item.monWater || item.monRoom) {
      arr.push({name: 'Понедельник', room: item.monRoom ? item.monRoom : '', water: item.monWater ? item.monWater : ''})
    }

    if (item.tueRoom || item.tueWater) {
      arr.push({name: 'Вторник', room: item.tueRoom ? item.tueRoom : '', water: item.tueWater ? item.tueWater : ''})
    }

    if (item.wedRoom || item.wedWater) {
      arr.push({name: 'Среда', room: item.wedRoom ? item.wedRoom : '', water: item.wedWater ? item.wedWater : ''})
    }

    if (item.thuWater || item.thuRoom) {
      arr.push({name: 'Четверг', room: item.thuRoom ? item.thuRoom : '', water: item.thuWater ? item.thuWater : ''})
    }

    if (item.friWater || item.friRoom) {
      arr.push({name: 'Пятница', room: item.friRoom ? item.friRoom : '', water: item.friWater ? item.friWater : ''})
    }

    if (item.satRoom || item.satWater) {
      arr.push({name: 'Суббота', room: item.satRoom ? item.satRoom : '', water: item.satWater ? item.satWater : ''})
    }

    if (item.sunRoom || item.sunWater) {
      arr.push({name: 'Воскресенье', room: item.sunRoom ? item.sunRoom : '', water: item.sunWater ? item.sunWater : ''})
    }

    return arr;
  }

}
