











































import {Component, Vue} from 'vue-property-decorator';

export type FooterLinks = { icon: string; url: string }

@Component({})
export default class AquawayFooter extends Vue {
  links: FooterLinks[] = [
    {
      icon: 'mdi-facebook',
      url: 'https://www.facebook.com/aquaway.ee'
    },
    {
      icon: 'mdi-instagram',
      url: 'https://www.instagram.com/aquaway.ee/'
    },
    {
      icon: 'mdi-google',
      url: 'mailto:aquaway.ujumiskool@gmail.com'
    }
  ]

}
