









import {Component, Vue} from "vue-property-decorator";
import TileLayer from "ol/layer/Tile";
import {TileJSON, XYZ} from "ol/source";
import {Feature, View} from "ol";
import Map from 'ol/Map';
import {register} from "ol/proj/proj4";
import proj4 from "proj4";
import {Extent} from "ol/extent";
import {get as getProjection} from 'ol/proj';
import googleDriveService from "@/service/google-drive.service";
import {AQUAWAY_SPREADSHEET_ID} from "@/constants/google-spreadsheet-constant";
import {Place} from "@/model/place";
import {Fill, Icon, Style, Text} from "ol/style";
import {Point} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
/* eslint-disable */
import markerIconBlue from "../../assets/icons/place.png";
import {createStringXY} from "ol/coordinate";
import {MousePosition} from "ol/control";
import {Tile} from "ol/layer";
import {MAP_TILER_KEY} from "@/constants/map-key";

export const MAP_EXTEND_EST: Extent = [40500, 5993000, 1064500, 7017000];

@Component({})
export default class MapComponent extends Vue {
  map: Map | undefined;
  places: Place[] = [];
  vectorSource: VectorSource | undefined;

  placesLayer: VectorLayer<any> | undefined;

  mounted(): void {
    this.initMap();
    this.fetch();
  }

  initMap(): void {
    proj4.defs(
        'EPSG:3301',
        '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 ' +
        '+lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
    );
    register(proj4);
    const epsg3301 = getProjection('EPSG:3301')!;
    epsg3301.setExtent(MAP_EXTEND_EST);

    this.vectorSource = new VectorSource({
      features: [],
    });
    this.placesLayer = new VectorLayer({
      source: this.vectorSource,
    });

    const source = new TileJSON({
      url: `https://api.maptiler.com/maps/winter-v2/tiles.json?key=${MAP_TILER_KEY}`, // source URL
      tileSize: 512,
      crossOrigin: 'anonymous'
    });

    this.map = new Map({
      target: 'map',
      layers: [
        new Tile({
          source: source
        }),
        // new TileLayer({
        //   source: new XYZ({
        //     attributions: 'Estonian Land Board Grayscale Basemap',
        //     attributionsCollapsible: false,
        //     projection: 'EPSG:3301',
        //     crossOrigin: 'anonymous',
        //     url: 'https://tiles.maaamet.ee/tm/tms/1.0.0/hallkaart@LEST/{z}/{x}/{-y}.png'
        //   })
        // }),
        this.placesLayer
      ],
      view: new View({
        projection: epsg3301,
        center: [542762, 6589038],
        zoom: 10,
        maxZoom: 14,
        minZoom: 5
      })
    });
    //  this.map.addControl(this.mousePositionControl());
  }

  mousePositionControl(): MousePosition {
    return new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:3301',
      className: 'custom-mouse-position',
      target: document.getElementById('mouse-position')!,
    });
  }

  fetch(): void {
    googleDriveService.readData(AQUAWAY_SPREADSHEET_ID).then(response => {
      response.loadInfo().then(() => {
        const sheet = response.sheetsByIndex[2]
        sheet.getRows()
            .then((r: Place[]) => {
              r.filter(i => i.id).sort((a, b) => (a?.id! > b?.id! ? 1 : -1)).forEach((item: Place) => {
                this.places.push(
                    item
                )
                this.addPlaceToMap(item);
                this.map?.getView().fit(this.vectorSource?.getExtent()!, {padding: [50, 50, 50, 50]})
              });
            })
      })
    })
  }

  addPlaceToMap(place: Place) {
    const iconFeature = new Feature({
      geometry: new Point([place.x, place.y]),
      name: place.name
    });
    iconFeature.setStyle(this.createStyleWithLabel(place.name));
    if (this.vectorSource) {
      this.vectorSource?.addFeature(iconFeature);
    }
  }

  createStyleWithLabel(text: any): Style {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: markerIconBlue,
        displacement: [0, 10]
      }),
      text: new Text({
        font: 'bold 12px sans-serif',
        textAlign: 'center',
        justify: 'center',
        text: text.replace('<br>', '\n'),
        fill: new Fill({
          color: [0, 0, 0, 1],
        }),
        backgroundFill: new Fill({
          color: [0, 0, 0, 0],
        }),
        padding: [40, 2, 2, 2],
        offsetY: 30
      }),
    });
  }

}
