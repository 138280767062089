









import {Component, Vue} from 'vue-property-decorator'
import WorkoutPlanList from "@/components/workoutplan/WorkoutPlanList.vue";

@Component({
  components: {WorkoutPlanList}
})
export default class WorkoutPlan extends Vue {
}
