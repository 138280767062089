import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
Vue.extend().component('vue-sound')

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        dark: false, themes: {
            light: {
                primary: '#333333',
                background: '#fff'
            },
            dark: {
                primary: '#fff',
                background: '#272727'
            }
        }
    },
});
